@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");

/* Global Elements */
:root {
    --amer-blue: #003366;
    --amer-teal: #33cccc;
    --base-white: #fff;
    --link-blue: #00539b;
    --expense-red: #993300;
    --life-blue: #6699cc;
    --mortgage-orange: #ff6633;
    --senior-green: #847a18;
    --medicare-yellow: #cc9900;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* IE 11 error */
.browserWarning {
    padding: 20px;
    text-align: center;
    width: 96%;
    left: 2%;
    z-index: 10000;
    border: 2px solid;
    position: relative;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #856404;
}

.card {
    white-space: pre-line;
}

.policyCard {
    white-space: pre-line;
    border-width: 1px;
    border-color: #1c2f67;
    border-style: solid;
    margin-bottom: 5px;
}

.coverageCard {
    min-height: 255px;
    margin-bottom: 5px;
}

.financialActivityCardBody {
    min-height: 160px;
    background-color: #ffffff;
}

body {
    font-family: sans-serif;
    background-color: #eee !important;
}

.navMenu {
    justify-content: end;
    padding-top: 10px;
}

.nav-link {
    color: #ffffff !important;
}

.nav-link:hover {
    color: #ffffff !important;
}

.nav-tabs .nav-link {
    color: #0260ed !important;
    margin-bottom: 0px !important;
}

.nav-tabs .nav-link:hover {
    color: #0260ed !important;
    font-weight: 620 !important;
}

.nav-link.active,
.nav-item.show .nav-link {
    color: black !important;
    font-weight: 620 !important;
}
.nav-link.active:hover,
.nav-item.show .nav-link {
    color: black !important;
    font-weight: 620 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: white !important;
    background: none;
    /* background-color: #1C2F67!important; */
    font-weight: 620 !important;
}

.nav-pills .nav-link:hover {
    color: white !important;
    font-weight: 620 !important;
}

.container {
    /* max-width: 500px; */
    /* margin: 30px auto; */
    overflow: auto;
    min-height: 300px;
    /* border: 1px solid steelblue; */
    /* padding: 30px; */
    /* border-radius: 5px; */
}

.logo {
    padding-left: 10px;
}

.logoCell {
    padding-top: 15px;
}

.whiteBackground {
    background-color: #fff !important;
}

.cssTag {
    color: #000000;
}

.menuCell {
    text-align: right;
}

.dropdown-item.active .dot {
    height: 25px;
    width: 25px;
    margin-top: 1px;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
}

.dropdown-item.active .dotNumber {
    color: #1a1c4a;
    font-weight: 600;
    margin-top: 1px;
}

.dot {
    height: 25px;
    width: 25px;
    margin-top: 2px;
    background-color: #1a1c4a;
    border-radius: 50%;
    display: inline-block;
}

.dotNumber {
    color: #fff;
    font-weight: 600;
    margin-top: 1px;
}

@media screen and (max-width: 400px) {
    .logo {
        width: 125px;
    }
    .menuCell {
        text-align: left !important;
    }
    .navMenu {
        justify-content: start;
        padding-top: 10px;
    }
    .banner-container {
        max-width: 390px;
    }
    .banner-iframe {
        width: 365px !important;
        height: 225px !important;
    }
    .banner-container-parent {
        min-height: 75vh !important;
    }
}
@media screen and (max-width: 575px) {
    .logo {
        width: 150px;
    }
    .menuCell {
        text-align: left !important;
    }
    .navMenu {
        justify-content: start;
        padding-top: 10px;
    }
    .banner-container {
        max-width: 390px;
    }
    .banner-iframe {
        width: 365px !important;
        height: 225px !important;
    }
    .banner-container-parent {
        min-height: 75vh !important;
    }
    .financialActivityCardBody {
        font-size: 0.9rem !important;
    }
}
@media screen and (min-width: 576px) {
    .logo {
        width: 150px;
    }
    .menuCell {
        text-align: left !important;
    }
    .navMenu {
        justify-content: start;
        padding-top: 10px;
    }
    .banner-container {
        max-width: 535px;
    }
    .banner-iframe {
        width: 510px !important;
        height: 335px !important;
    }
    .banner-container-parent {
        min-height: 75vh !important;
    }
}
@media screen and (min-width: 768px) {
    /* .logo {
    width: 150px;
  } */
    .menuCell {
        text-align: left !important;
    }
    .navMenu {
        justify-content: start;
        padding-top: 10px;
    }
    .banner-container {
        max-width: 625px;
    }
    .banner-iframe {
        width: 600px !important;
        height: 375px !important;
    }
    .banner-container-parent {
        min-height: 75vh !important;
    }
}
@media screen and (min-width: 992px) {
    /* .logo {
    width: 150px;
  } */
    .banner-container {
        max-width: 730px;
    }
    .banner-iframe {
        width: 600px !important;
        height: 375px !important;
    }
    .banner-container-parent {
        min-height: 75vh !important;
    }
}
@media screen and (min-width: 1200px) {
    /* .logo {
    width: 150px;
  } */
}
@media screen and (min-width: 1400px) {
    /* .logo {
    width: 150px;
  } */
}

.header-customer-name {
    text-align: left;
    font-family: sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 14px;
}

.header {
    /*background-color: #003366;*/
    background-color: var(--amer-blue);
    color: white;
    min-height: 60px;
}

.banner-container-parent {
    min-height: 100vh;
}

.banner-container {
    min-width: 350px;
    background-color: #eeeeee;
    border-style: none;
    box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.26);
}

.banner-iframe {
    width: 600px;
    height: 335px;
}

.btnRounded {
    display: inline-block;
    background: #1a1c4a !important;
    color: #f4f4f4;
    border: none;
    padding: 10px 1px;
    margin: 5px;
    border-radius: 25px;
    cursor: pointer;
    text-decoration: none;
    font-size: 15px;
    font-family: inherit;
    width: 100px !important;
}

.btnRounded:focus {
    outline: none;
}

.btnRounded:active {
    transform: scale(0.98);
}

.btn-block {
    display: block;
    width: 100%;
}

.btn {
    background-color: #1a1c4a !important;
    color: #fff;
}
.btn:hover {
    background-color: #1a1c4a !important;
    color: #fff;
    opacity: 0.7;
}
.btn-secondary {
    background-color: #fff !important;
    color: #000;
}
.btn-secondary:focus {
    background-color: #fff !important;
    color: #000;
}
.btn-secondary:active {
    background-color: #fff !important;
    color: #000;
}
.dropdown-item {
    color: #1a1c4a;
    text-decoration: none;
    background-color: #fff;
}
.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #1a1c4a;
}

.form-control input {
    width: 90% !important;
}

.form-control-check input {
    flex: 1 1 !important;
}

.form-control {
    margin: 10px 0;
}

.form-control label {
    display: block;
}

.form-control input {
    width: 100%;
    height: 40px;
    margin: 5px;
    padding: 3px 7px;
    font-size: 17px;
}

.form-control-check {
    /* display:flex; */
    align-items: center;
    justify-content: space-between;
}

.form-control-check label {
    flex: 1;
}

.form-control-check input {
    flex: 2;
    height: 20px;
}

footer {
    margin-top: 10px;
    text-align: center;
}

.subFooter {
    background-color: #003366;
    font-size: 75%;
    padding: 2em 0;
    color: white;
}

#footer .container,
#subFooter .container {
    margin: 0 auto;
    min-height: 150px;
}

.likeBtn {
    text-align: center;
    padding-bottom: 15px;
}

.content a,
.subFooter a {
    color: #ffffff;
    text-decoration: underline;
}

.content a:hover,
.subFooter a:hover {
    color: #ffffff;
    text-decoration: underline;
    font-weight: 620 !important;
}

.subFooter .divider {
    margin: 0 0.5em;
    color: #ffffff;
    text-decoration: none;
}

.siteStatus {
    cursor: pointer;
    color: #ffffff !important;
    text-decoration: underline;
}

.siteStatus:hover {
    color: #ffffff !important;
    text-decoration: underline;
    font-weight: 620 !important;
}

/* FAQ page */
#faqAccordion .accordion-button {
    background-color: #eee;
    box-shadow: none;
    color: #000;
    font-weight: 620 !important;
}

#faqAccordion .accordion-button::after {
    background-color: #eee;
    box-shadow: none;
    color: #000;
    font-weight: 620 !important;
}

/* enable absolute positioning */
.inner-addon {
    position: relative;
}

/* style icon */
.inner-addon .biCustom {
    position: absolute;
    padding: 10px;
    pointer-events: none;
}

/* align icon */
.left-addon .biCustom {
    left: 0px;
}
.right-addon .biCustom {
    right: 0px;
}

/* add padding  */
.left-addon input {
    padding-left: 30px;
}
.right-addon input {
    padding-right: 30px;
}

/* FAQ Admin */
.form-control .inactiveCheckbox {
    width: 50px !important;
}

textarea.form-control {
    margin: 5px 0;
}

.form-control textarea {
    width: 90% !important;
}

/* Policy Details */
.fieldLabel {
    font-weight: 620 !important;
}

.fieldValue {
    font-weight: normal !important;
}

/* Register */
.min-vh-70 {
    min-height: 70vh !important;
}

/* Nav/Common */

/* Home */
.home-card {
    min-width: 350px;
    background-color: #eeeeee;
    border-style: none;
    box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.26);
}

/* Impersonate */
.policyOwnerTableHeader {
    background-color: #003366;
    color: white;
    height: 40px;
    text-align: center;
}

.hideOverflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* Beneficiary Change */
.smallHelpBox {
    font-size: 12px;
    border-style: solid;
    border-color: #1c2f67;
    border-width: 1px;
    width: 400px;
    position: absolute;
    background-color: white;
    padding: 10px;
    z-index: 999;
}

.bigHelpBox {
    font-size: 12px;
    border-style: solid;
    border-color: #1c2f67;
    border-width: 1px;
    width: 600px;
    position: absolute;
    background-color: white;
    padding: 10px;
    z-index: 999;
    margin-left: -70px;
}

.bigHelpBoxContent {
    font-size: 12px;
    border-style: solid;
    border-color: grey;
    border-width: 1px;
    width: 575px;
    height: 300px;
    overflow: auto;
    background-color: white;
    padding: 10px;
}

.gridSharePercentage {
    width: 80px;
}

.enrolled {
    color: #08680a;
    font-weight: bold;
}

/*\
 * Restore Bootstrap 3 "hidden" utility classes.
\*/

/* Breakpoint XS */
@media (max-width: 575px) {
    .hidden-xs-down,
    .hidden-sm-down,
    .hidden-md-down,
    .hidden-lg-down,
    .hidden-xl-down,
    .hidden-xs-up,
    .hidden-unless-sm,
    .hidden-unless-md,
    .hidden-unless-lg,
    .hidden-unless-xl {
        display: none !important;
    }
}

/* Breakpoint SM */
@media (min-width: 576px) and (max-width: 767px) {
    .hidden-sm-down,
    .hidden-md-down,
    .hidden-lg-down,
    .hidden-xl-down,
    .hidden-xs-up,
    .hidden-sm-up,
    .hidden-unless-xs,
    .hidden-unless-md,
    .hidden-unless-lg,
    .hidden-unless-xl {
        display: none !important;
    }
}

/* Breakpoint MD */
@media (min-width: 768px) and (max-width: 991px) {
    .hidden-md-down,
    .hidden-lg-down,
    .hidden-xl-down,
    .hidden-xs-up,
    .hidden-sm-up,
    .hidden-md-up,
    .hidden-unless-xs,
    .hidden-unless-sm,
    .hidden-unless-lg,
    .hidden-unless-xl {
        display: none !important;
    }
}

/* Breakpoint LG */
@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-lg-down,
    .hidden-xl-down,
    .hidden-xs-up,
    .hidden-sm-up,
    .hidden-md-up,
    .hidden-lg-up,
    .hidden-unless-xs,
    .hidden-unless-sm,
    .hidden-unless-md,
    .hidden-unless-xl {
        display: none !important;
    }
}

/* Breakpoint XL */
@media (min-width: 1200px) {
    .hidden-xl-down,
    .hidden-xs-up,
    .hidden-sm-up,
    .hidden-md-up,
    .hidden-lg-up,
    .hidden-xl-up,
    .hidden-unless-xs,
    .hidden-unless-sm,
    .hidden-unless-md,
    .hidden-unless-lg {
        display: none !important;
    }
}

.skip {
    background: #eee;
    font-weight: 600;
    height: 30px;
    left: 45%;
    padding: 8px;
    position: absolute;
    transform: translateY(-100%);
    transition: transform 0.3s;
}

.skip:focus {
    transform: translateY(0%);
}

.custom-h1 {
    font-size: calc(1.275rem + 0.3vw);
    margin: 0px 0px 0.5rem 0px;
    font-weight: 500;
    line-height: 1.2;
}

/*** address accessibility color contrast concerns ***/
/* register input border */
#register > section > div > div > form > div > input.form-control {
    border: 1px solid #5c6e7f !important;
}

/* error messages */
.text-danger {
    color: #c9263c !important;
}

/* act as a policyholder search */
input[name="searchText"].form-control {
    border: 1px solid #6b7594 !important;
}

/* policy details tab link text */
#policyDetailTabs-tab-payments.nav-link,
#policyDetailTabs-tab-details.nav-link,
#policyDetailTabs-tab-docs.nav-link,
#policyDetailTabs-tab-forms.nav-link,
#policyDetailTabs-tab-faqs.nav-link,
#policyDetailTabs-tab-service.nav-link,
#policyDetailTabs-tab-homeoffice.nav-link {
    color: #6154e8 !important;
}

/* policy details tab link text active (black) */
#policyDetailTabs-tab-payments.nav-link.active,
#policyDetailTabs-tab-details.nav-link.active,
#policyDetailTabs-tab-docs.nav-link.active,
#policyDetailTabs-tab-forms.nav-link.active,
#policyDetailTabs-tab-faqs.nav-link.active,
#policyDetailTabs-tab-service.nav-link.active,
#policyDetailTabs-tab-homeoffice.nav-link.active {
    color: #000 !important;
}

#registerHeading {
    font-size: calc(1.325rem + 0.9vw);
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

.homeOfficeHeadingToggle {
    font-size: 1.25rem;
}

button.homeOfficeCollapse {
    border: none;
    text-align: left;
}

.skip-link {
    margin-right: 1rem;
    position: absolute;
    transform: translateX(-200%);
    transition: transform 0.3s;

    &:focus {
        position: static;
        transform: translateX(0);
    }
}

#removeUploadTooltip > .tooltip-inner {
    background-color: #1a1c4a;
    color: #fff;
}

.react-pdf__Document {
    height: 550px;
    overflow-y: auto;
    border-style: solid;
    border-color: darkgray;
}

.eDelivery-flag {
    color: #1a1c4a;
}

.grace-warning {
    border: 1px solid red;
}

/***** Add New Bank Draft *****/
#add-new-bank-draft-otp-link {
    text-decoration: none;
    font-style: italic;
}

.input-group {
    width: auto;
}

.input-group-text {
    background-color: #eee;
    border: none;
}

.label-text-padding {
    padding-left: 50px;
}

.form-control {
    margin: 0px;
}

/* .required::after {
    content: " *";
    color: red;
} */

.dateSelectorField {
    background-color: #fff;
}

#dateSelectorFieldContainer {
    margin-left: 0.1em;
    margin-right: 0.1em;
}
