.manage-my-payments-section {
    background-color: #d9d9d9;
}

.disabled-link {
    color: #0d6efd;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
    text-decoration: none;
}

.manage-my-payments-link {
    color: #0260ed !important;
    margin-bottom: 0px !important;
    border: 0;
    background-color: #d9d9d9;
}

.manage-my-payments-link:hover {
    color: #0260ed !important;
    font-weight: 620 !important;
}

.provide-payment-info {
    font-weight: bold !important;
}

.bank-account-payment {
    background-color: white;
}

.manage-my-payments-border {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #33cccc;
}

.policy-details {
    padding-left: 25%;
}

.loadingPolicyDataOverlay {
    background-color: #e4e3e3;
}

.loadingPolicyDataOverlay div {
    min-height: 0 !important;
    position: relative;
    top: calc(50% - 10px);
}

.bank-review-header {
    font-weight: bold;
}

.bi-asterisk {
    font-size: 8px;
    vertical-align: top;
}